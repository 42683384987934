import React from 'react';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


import './App.css';
import './App-responsive.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="Content">
        <div className="Info">
          <div className="ProfilePicture" />
          <div className="PersonalInfo">
            Jag startade eget mars 2016 efter att ha arbetat inom ekonomiområdet i över 25 år.
            Mestadels har jag arbetat som ekonomichef/CFO och har ansvarat för alla delar inom en ekonomiavdelning och även HR, IT och övrig administration.
            Jag har varit projektledare vid implementation av affärssystem och tidsredovisning/lönesystem.
            I mina tidigare anställningar har jag varit med och byggt upp ekonomiavdelningar från grunden.
          </div>
          <div className="ContactInfo">
            <h2>Kontakt</h2>
            <p><FontAwesomeIcon icon={solid('envelope')} /> christina@2ekonsult.se</p>
            <p><FontAwesomeIcon icon={brands('linkedin')} /> <a href="https://www.linkedin.com/in/christina-pettersson-b52937114/" target="_blank">LinkedIn</a></p>
            <p><FontAwesomeIcon icon={solid('phone')} /> 072-234 44 66</p>
            <p><FontAwesomeIcon icon={solid('location-dot')} /> Myragatan 8 331 51 Värnamo</p>
          </div>
        </div>
        <div className="Experience">
          <div className="ExperienceIntro">
            Företaget startade i mars 2016 och erbjuder interim lösningar inom ekonomifunktionen, projektledning samt översyn/utveckling av administrativa processer.
          </div>
          <div className="ProjectWrapper">
            <h2>Nuvarande uppdrag</h2>
            <p>
              <span>Konsultuppdrag</span><br></br>
              Oktober 2021 - Nuvarande<br></br>
              EMEA Finance organisation<br></br>
              Rådgivning och projektledning vid implementering av Microsoft D365 i Europa
            </p>
          </div>
          <div className="ProjectWrapper">
            <h2>Tidigare uppdrag</h2>
            <p>
              <span>Interim CFO</span><br></br>
              Januari 2019 - Oktober 2021<br></br>
              Dotterbolag till internationnell börsnoterad koncern<br></br>
              Ansvar för bokslut, budget och rapportering
            </p>
          </div>
          <div className="ProjectWrapper">
            <p>
              <span>Projektledare</span><br></br>
              Augusti 2018 - Januari 2019<br></br>
              Börsnoterad industrikoncern, oms 4 200 msek<br></br>
              Projektledare implementering IFRS 16
            </p>
          </div>
          <div className="ProjectWrapper">
            <p>
              <span>Interim CFO</span><br></br>
              April 2017 - Augusti 2018<br></br>
              Industiföretag, oms 100 msek<br></br>
              Ansvar för bokslut, rapportering och budget/prognos
            </p>
          </div>
          <div className="ProjectWrapper">
            <p>
              <span>Interim Ekonomiansvarig</span><br></br>
              Mars 2016 - September 2020<br></br>
              Kooperativ<br></br>
              Effektivisering av löneprocess och lönesystem. Utveckling av rutiner för bokslut, rapportering och budget/prognos.
            </p>
          </div>
          <div className="ProjectWrapper">
            <p>
              <span>Konsultuppdrag</span><br></br>
              Februari 2017 - Augusti 2018<br></br>
              Industriföretag, oms 130 msek<br></br>
              Utveckling av processer för bokslut, rapportering och budgetarbete
            </p>
          </div>
          <div className="ProjectWrapper">
            <p>
              <span>Interim Projektekonom</span><br></br>
              November 2016 - April 2017<br></br>
              Jönköpings Universitet<br></br>
              Ekonomisk uppföljning och budget av forskningsprojekt
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
